import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={'././airhouse_app_icon_9.28.png'} className="App-logo" alt="logo" />
        <p>
          Success!
        </p>
        <br/>
        <small>
          You have successfully installed the <i>Airhouse Shipping Optimizer</i> app on your Shopify store.
        </small>
        <br/>
        <small>
          You'll receive an email from your contact on the Sales team shortly.
        </small>
        <br/>
        <small>
          Feel free to close this window.
        </small>
      </header>
    </div>
  );
}

export default App;
